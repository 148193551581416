<template>
  <div class="all-height">
    <div class="">
      <v-card :loading="loading" :disabled="loading" loader-height="2">
        <v-card-text class="pb-0">
          <div class="d-flex align-center">
            <v-btn
              rounded
              color="primary"
              @click="
                locationDialog = true;
                locationsData = {};
                errors = {};
              "
              elevation="0"
              small
              class="mr-2 mt-1"
              ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
            >
            <lb-string
              label="Search"
              outlined
              hidedetails
              v-model="seachList"
            ></lb-string>
            <v-spacer></v-spacer>
            <v-chip label small class="border-left-error" outlined
              >Disabled</v-chip
            >
            <v-btn icon small class="ml-2" @click="refreshData()"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </div>
        </v-card-text>
        <v-card-text>
          <v-simple-table class="pbc-view-table" dense>
            <thead>
              <tr>
                <th scope="#">#</th>
                <th scope="Name">Name</th>
                <th scope="columns">Type</th>
                <th scope="columns">Contact Person</th>
                <th scope="columns">Email</th>
                <th scope="columns">Mobile</th>
                <th scope="columns">PAN Number</th>
                <th scope="columns">GST Number</th>

                <th scope="Action">Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v, k) in searchResult">
                <tr :key="k">
                  <td
                    :class="`${
                      v.status ? 'border-left-transparent' : 'border-left-error'
                    }`"
                  >
                    <span>{{ k + 1 }}</span>
                  </td>
                  <td>
                    <span
                      v-html="$nova.getHighlitedText(v.name || '', seachList)"
                    ></span>
                  </td>
                  <td>{{ v.type }}</td>
                  <td>{{ v.contactperson }}</td>
                  <td>{{ v.contactemail }}</td>
                  <td>{{ v.contactno }}</td>
                  <td>{{ v.panno }}</td>
                  <td>{{ v.gstno }}</td>

                  <td>
                    <v-menu offset-y transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="view-on-hover-item-d mx-0 px-0"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          ><v-icon>mdi-menu</v-icon></v-btn
                        >
                      </template>
                      <v-list dense class="pa-0 border-left-default">
                        <v-list-item @click="locationEdit(v)">
                          <v-list-item-title
                            ><v-icon left>mdi-pencil</v-icon
                            >Edit</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-if="v.status"
                          @click="locationDiable(v._id)"
                        >
                          <v-list-item-title
                            ><v-icon color="error" left> mdi-cancel</v-icon>
                            Disabled
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-else @click="locationEnable(v._id)">
                          <v-list-item-title
                            ><v-icon color="success" left
                              >mdi-check-bold</v-icon
                            >
                            Enabled
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="locationDialog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="locationsData._id"
            >Update Location</span
          >
          <span class="subtitle-1 white--text" v-else>Create New Location</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="locationDialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                label="Name"
                v-model="locationsData.name"
                :error="errors['name']"
              />
            </v-col>

            <v-col cols="6" class="my-0 py-1">
              <lb-dropdown
                class="flex-grow-1"
                label="Type"
                v-model="locationsData.type"
                :items="locationTypes"
                itemtext="name"
                itemvalue="value"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-string
                label="Contact Person"
                v-model="locationsData.contactperson"
                :error="errors['contactperson']"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-string
                label="Number"
                v-model="locationsData.contactno"
                :error="errors['contactno']"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-string
                label="Email"
                v-model="locationsData.contactemail"
                :error="errors['contactemail']"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-string
                label="GST Number"
                v-model="locationsData.gstno"
                :error="errors['gstno']"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-string
                label="PAN Number"
                v-model="locationsData.panno"
                :error="errors['panno']"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="locationsData._id"
            @click="locationUpdate()"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="locationCreate()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-for="(sanckbar, index) in sanckbars.filter((s) => s.showing)"
      :key="sanckbar.text + Math.random()"
      v-model="sanckbar.showing"
      :color="sanckbar.color"
      :timeout="sanckbar.time"
      auto-heigh
      :style="`bottom :${index * 60 + 8}px`"
      left
    >
      {{ sanckbar.text }} {{ index }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locationTypes: ["Branch", "Subsidiary", "Associate"],
      errors: {},
      locationDialog: false,
      locationsGetData: [],
      locationsData: {},
      loading: false,
      seachList: "",
      locationsType: [],
      counter: 0,
      sanckbar: {
        showing: false,
        color: "",
        text: "",
        time: 1000,
      },
      sanckbars: [],
    };
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },

  computed: {
    searchResult() {
      let tempList = [...this.locationsGetData];
      let active = true;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return item.name.toUpperCase().includes(this.seachList.toUpperCase());
        });
      } else active = false;

      for (const i of tempList) {
        i.active = active;
      }
      return tempList;
    },
  },
  methods: {
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/location/get")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.locationsGetData = dt.data.data;
            this.axios
              .post("/v2/location/formselects")
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.locationTypes = dt.data.data[0].type;
                } else throw new Error(dt.data.message);
              })
              .catch((err) => {
                this.$store.commit("sbError",err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    locationCreate() {
      // console.log("DD");
      // console.log(this.locationsData, "FF");

      this.loading = true;
      this.axios
        .post("/v2/location/add", { data: this.locationsData })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sankbarShow",{ text: "Location added successfully",type: "success"});
            //this.$store.commit("sbSuccess", "Location added successfully");
            this.refreshData();
            this.locationsData = {};
            this.locationDialog = false;
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              // this.$store.commit("sankErro",dt.data.message || "Unknown error!");

              //this.$store.commit("sankbarShow",{ text: dt.data.message,type: "error"});
              this.$store.commit("sbError",dt.data.message || "Unknown error!");
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            } else throw new Error(dt.data.message || "Error update location");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    locationUpdate() {
      this.loading = true;
      this.axios
        .post("/v2/location/edit/" + this.locationsData._id, {
          data: this.locationsData,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Location updated successfully");
            this.refreshData();
            this.locationsData = {};
            this.locationDialog = false;
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            } else
              throw new Error(dt.data.message || "Error updateing location");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    locationEdit(item) {
      this.locationDialog = true;
      this.locationsData = item;
    },
    locationEnable(id) {
      this.loading = true;
      this.axios
        .post("/v2/location/enable/" + id)
        .then((dt) => {
          this.refreshData();
          console.log(dt, "enable");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    locationDiable(id) {
      this.loading = true;
      this.axios
        .post("/v2/location/disable/" + id)
        .then((dt) => {
          this.refreshData();
          console.log(dt, "enable");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // showMessage(msg) {
    //   console.log("GGG", this.counter);
    //   (this.sanckbar = {
    //     showing: true,
    //     color: "green",
    //     text: msg,
    //     time: 1000,
    //   }),
    //     this.sanckbars.push(this.sanckbar);
    // },
  },
};
</script>
